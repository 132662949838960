@import '../../mixins';

.catalog {
  & .section {
    &__head {
      position: relative;
      z-index: 1;
      grid-column: 1/2;
      grid-row: 1;

      padding-right: rem(40);
      height: auto;
      flex-direction: column;
      align-items: start;
      justify-content: space-between;
      flex-shrink: 0;
      gap: rem(20);

      transition: opacity var(--animation-timing) var(--cubic-bezier),
        visibility var(--animation-timing) var(--cubic-bezier);

      @include mediaBigDesktop {
        padding-right: big(40);
        gap: big(20);
      }


      &_hidden {
        opacity: 0;
        visibility: hidden;

        pointer-events: none;
      }
    }
    
    &__swiper-navigation {
      & .button {
        position: absolute;
        top: 50%;
        z-index: 3;
      
        transform: translateY(-50%);
        transition: color var(--animation-timing) var(--cubic-bezier), border-color var(--animation-timing) var(--cubic-bezier), opacity var(--animation-timing) var(--cubic-bezier);
        
        &:first-child {
         left: 0;
        }
        
        &:last-child {
         right: 0;
        }
        
        &:disabled {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &__navigation {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: rem(40);

      @include mediaBigDesktop {
        gap: big(40);
      }

      @include mediaTablet {
        gap: rem(30);
      }

      @include mediaTablet {
        gap: rem(15);
      }

      @include mediaMobile {
        display: flex;
        gap: rem(10);
      }

      & .button {
        @include mediaMobile {
          display: none;
        }
      }
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: stretch;
    justify-content: start;
    column-gap: rem(40);

    @include mediaBigDesktop {
      column-gap: big(40);
    }

    @include mediaTablet {
      display: flex;
      flex-direction: column;
      gap: rem(40);
    }

    @include mediaMobile {
      gap: rem(33);
    }
  }

  &__description {
    width: 100%;

    color: var(--section-text-color-secondary);
    font: var(--font-body-M);
  }

  &__content {
    position: relative;
    z-index: 2;
    grid-column: 2/4;
    grid-row: 1;

    width: 100%;
  }

  &__swiper {
    overflow: visible;

    & .swiper {
      &-slide {
        width: fit-content;
      }
    }
  }

  & .category-card {
    height: rem(680);

    @include mediaDesktop {
      height: rem(700);
    }

    @include mediaBigDesktop {
      height: big(700);
    }

    @include mediaLaptop {
      height: rem(525);
    }

    @include mediaTablet {
      height: rem(501);
    }

    @include mediaMobile {
      height: rem(426);
    }
  }
}
